import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import CertificationPage from '../../templates/Certification';

const index = {
  header: {
    title: 'certificationTitle',
    subTitle: 'certificationSubTitle',
    image: 'sports_medal.png',
  },
  cert: {
    title: 'certificationCertTitle',
    subTitle: 'certificationCertSubTitle',
    data: [
      {
        title: 'certificationCertJuniorTitle',
        text: 'certificationCertJuniorSubTitle',
        image: 'smile2.png',
        button: {
          text: 'certificationCertJuniorButton',
          link: 'certificationCertJuniorButtonLink',
          event: 'receive_junior'
        }
      },
      {
        title: 'certificationCertMiddleTitle',
        text: 'certificationCertMiddleSubTitle',
        image: 'smile3.png',
        button: {
          text: 'certificationCertMiddleButton',
          link: '/form/certification-employees/#level=middle',
          event: 'pass_certification_middle'
        }
      },
      {
        title: 'Senior',
        text: 'certificationCertSeniorSubTitle',
        image: 'smile1.png',
        button: {
          text: 'certificationCertSeniorButton',
          link: '/form/certification-employees/#level=senior',
          event: 'pass_certification_senior'
        }
      }
    ]
  },
  corp: {
    title: 'certificationCorpTitle',
    subTitle: 'certificationCorpSubTitle',
    button: {
      text: 'certificationCorpButtons',
      link: '/form/certification-corp',
      event: 'order_corporate_certification'
    }
  },
  partner: {
    title: 'certificationPartnerTitle',
    subTitle: 'certificationPartnerSubTitle',
    button: {
      text: 'certificationPartnerButtons',
      link: '/form/certification-partner',
      event: 'pass_certification_partner'
    }
  },
};


const Certification = (props) => (
  <Layout {...props}>
    <CertificationPage
      data={index}
    />
  </Layout>
);

export default withIntl(Certification);

